@import url('../../globalStyles/globalStyles.css');

.payment-card {
    margin: 20px auto;
    width: 95%;
    padding: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffff;
    border-radius: 10px;
}

.payment-logo {
    width: 200px;
    font-weight: 700;
    font-size: 30px;
}

.payment-notify {
    font-size: 12px;
    margin-bottom: 10px;
}

.topup-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 15px;
    margin-top: 20px;
}

#option-title {
    font-size: 16px;
    font-weight: bold;
    padding: 7px 4px;
    width: 100%;
    border-radius: 3px;
    margin: 0px 0px !important;
    display: inline-block;
    background-color: rgba(223, 223, 223, 0.534);
    color: rgb(61, 61, 61);
}

.option1-list {
    padding: 10px 20px;
}

.option1-list li {
    padding: 5px 0px;
}

#paybill {
    font-weight: bold;
}

.payment-input-group {
    width: 100%;
    margin: 10px 0px;
}

.payment-label {
    font-size: 14px;
    font-weight: bold;
    color: rgb(54, 54, 54);
}

.payment-input {
    padding: 7px 10px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #c0c0c081;
    font-size: 14px !important;
    background-color: #F2F2F2;
    width: 100%;
}

.payment-input:focus {
    border: 1px solid var(--blue-color);
    outline: none;
    background-color: rgb(255, 255, 255);
}

#error {
    font-size: 12px;
    color: rgb(221, 34, 34);
    margin-top: 5px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 780px) {
    .payment-card {
        width: 95%;

    }

    .login-form {
        width: 95%;
    }
}


.payment-btn {
    padding: 10px 7px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: var(--theme-color);
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    width: 120px;
    cursor: pointer;
}

.payment-btn:hover {
    opacity: 0.7 !important;
    transition: opacity .3s ease-in-out;
}

.btn-disabled{
    cursor: not-allowed;
    padding: 7px 7px;
    border: none;
    border-radius: 3px;
    margin-top: 20px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}




.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 2px solid rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) transparent rgb(254, 255, 255) transparent !important;
    animation: lds-dual-ring 0.9s linear infinite !important;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


.confirm-modal-inactive {
    display: none;
}

.confirm-modal-active {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.confirm-modal {
    position: relative;
    width: 500px;
    height: 200px;
    left: 0;
    top: 20%;
    margin: auto auto !important;
    background: #fff;
    border-radius: 10px;
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.4);
    text-align: center;
    overflow: hidden;
    animation: show-modal .7s ease-in-out;
    margin-top: 20px !important;
    z-index: 1000;
}

.modal.hide {
    animation: hide-modal .6s ease-in-out both;
}

.action-title {
    display: block;
    line-height: 24px;
    margin: 14px 0 5px 0;
    font-weight: bold;
    font-size: 20px;
}

.confirm-message {
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    margin: 0;
    padding: 0 30px;
    font-weight: 400;
}

.ta-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100px;
    position: absolute;
    padding-bottom:12px;
}

.dismiss-button {
    width: 40%;
    background: #1d45b4;
    border: none;
    border-radius: 5px;
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: background .3s ease-in-out;
}

.dismiss-button:hover {
    background: #4c6fcf;
}

.confirm-button {
    width: 40%;
    background-color: #3477ec;
    border: none;
    border-radius: 5px;
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: background .3s ease-in-out;
    margin-top: 10px !important;
}

.confirm-button:hover {
    background-color: #3477ec;
    opacity: 0.8;
}


@keyframes show-modal {
    0% {
        transform: scale(0);
    }

    60% {
        transform: scale(1.1);
    }

    80% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes hide-modal {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0);
    }
}