@import url('../../components/globalStyles/globalStyles.css');

.sidebar {
    min-height: 100vh;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 20px 16px;
    background-color: rgb(255, 255, 255);
    padding: 15px 5px;
    width: 120px !important;
    overflow-x: hidden;
    z-index: 2;

    position: sticky;
    top: 0;
}




#h-icon {
    font-size: 25px !important;
}

.heading-link {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 5px 0px;
    margin-bottom: 10px;
    text-decoration: none;
    color: rgb(82, 82, 82);
    font-size: 14px;
    border-radius: 5px;

}

.heading-link:hover {
    color: #00B4DB;
    font-weight: bold;
    background-color: #00b3db1f;
    border-radius: 7px;
}

.menu-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 7px 5px;
    text-decoration: none;
    color: rgb(82, 82, 82);
}

.menu-title:hover {
    color: #ffff !important;
    background-image: var(--theme-color);
    border-radius: 10px;
}

.submenu-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center !important;
    font-size: 16px;
    font-weight: 400;
}

#list-group-items {
    padding: 2px 0px;
    display: none;
    width: 90%;
    margin: auto;
    list-style: none;
}

.sidebar-item {
    display: flex;
    align-items: center;
    text-align: right !important;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: bold;
    color: black;
    text-decoration: none !important;
    list-style: none !important;
    padding: 7px 0px;
    font-weight: 500;
    margin-bottom: 3px;
}

.sidebar-item:hover {
    color: #ffff !important;
    background-image: var(--theme-color);
    border-radius: 5px;
}

.submenu-list-item {
    margin-bottom: 7px;
}


.submenu-list-item:hover #arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}


/* .submenu-list-item:hover .list-group-items {
    display: block !important;
    color: rgb(96, 139, 96) !important;
    opacity: 1;
    height: auto;
} */

#arrow {
    font-size: 12px;
    font-weight: bold;
}

.active {
    color: #00B4DB;
    font-weight: bold;
    background-color: #00b3db15;
    border-radius: 7px;
}

#icons {
    padding: 0px 5px;
}