@import url('../globalStyles/globalStyles.css');

.signup-page {
    min-height: auto !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
     background-image: url('../../../public/images/background.png');
     background-color: #ffff;
     background-repeat: no-repeat;
     background-size: 100%;
}

.signup-left-side {
    width: 45%;
    display: flex;
    align-items: center;
    height: 100vh;
}

.signup-left-content{
    width: 100%;
    margin: auto;
    padding: 10px 35px;
    color: #222222 !important;
}

.signup-lipachat-logo {
    width: 120px;
    display: block;
}

.signup-left-content-header {
    font-size: 25px;
    font-weight: bold;
    color: #222222 !important;
}

#signup-content-list {
    list-style: circle;
    padding: 10px 20px;
    color: #222222 !important;
}

#signup-content-item {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 0px;
    color: #222222 !important;
}

.signup-title2 {
    text-align: center;
}

.inputs-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 5px;
    padding: 0px !important;
}

.signup-form-section {
    background-color: #fff;
    width: 45%;
    display: flex;
    min-height: auto;
    border-radius: 10px;
    padding: 10px 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.signup-form {
    margin: auto auto !important;
    padding: 0px 12px;
    width: 100%;
    background-color: #fff;
}

.signup-welcome-header {
    font-size: 25px;
    padding: 10px 0px;
    font-weight: bold;
    color: #242525;
}

.signup-welcome-header2 {
    color: #5a6370;
    font-size: 14px;
}

.signup-input-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}

.signup-label {
    color: rgb(59, 59, 59);
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: 600;
}

.signup-input {
    padding: 7px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #c0c0c081;
    background-color: #F2F2F2;
}

.signup-input:focus {
    outline: none;
    border: 1px solid var(--blue-color);
    background-color: #ffffff;
}

.signup-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    background-image: var(--theme-color);
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
}

.signup-button:hover{
    opacity: 0.1 !important;
    transition: opacity .3s ease-in-out;
}


.signup-actions {
    margin-top: 15px;
}

.reset-link {
    text-decoration: none;
    color: #0083B0;
    font-size: 14px;
}


.signup-section {
    text-align: center;
}

.login-link {
    font-weight: 600;
    text-decoration: none;
    color: #0083B0;
}


#form-error {
    color: var(--danger);
    font-size: 12px;
}

.terms-condition-section{
    padding-top: 15px;
}

.terms-conditions{
    font-size: 12px;
}

#terms-privacy{
    font-weight: bold;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.terms-condition-modal{
    height: 450px;
    overflow-y: scroll;
}

@media screen and (max-width: 990px) {

    .signup-page {
        flex-direction: column;
    }

    .signup-left-side {
        width: 100%;
        height: 100vh;
    }

    .signup-form-section {
        width: 100%;
    }

    .signup-form {
        width: 50%;
    }

}

@media screen and (max-width: 780px) {
    .signup-form {
        width: 80%;
    }
}

@media screen and (max-width: 570px) {
    .signup-form {
        width: 95%;
    }
}



/* LOADING ANIMATION */
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 2px solid rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) transparent rgb(254, 255, 255) transparent !important;
    animation: lds-dual-ring 0.9s linear infinite !important;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


