@import url('../globalStyles/globalStyles.css');
.footer{
    background-color: var(--theme-color);
    position: relative;
    bottom: 0% !important;
    z-index: -1;
    width: 98%;
    margin-top: auto;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    padding: 15px;
    color: rgb(235, 228, 228);
   font-size: 16px;
   font-weight: bolder;
}
