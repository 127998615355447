:root{
    --theme-color: #00B4DB;
    --orange-color: #FF9F43;
    --blue-color:#00CFE8;
    --purple-color: #1B2850;
    --green-color: #28C76F;
    --red-color: #DC281E;

    --primary:#5b3cc4;
    --success:rgb(23, 201, 100);
    --danger:rgb(242, 19, 93);
    --warning:rgb(255, 130, 0);
    --dark:rgb(36, 33, 69);

    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
 }