

.delete-modal-inactive {
    display: none;
}

.delete-modal-active {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.delete-modal {
    position: relative;
    width: 500px;
    height: 210px;
    left: 0;
    top: 20%;
    margin: auto auto !important;
    background: #fff;
    border-radius: 3px;
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.4);
    text-align: center;
    overflow: hidden;
    animation: show-modal .7s ease-in-out;
    margin-top: 20px !important;
    z-index: 1000;
}

.modal.hide {
    animation: hide-modal .6s ease-in-out both;
}

#delete-img {
    margin-top: 24px;
}

.action-title {
    display: block;
    line-height: 24px;
    margin: 14px 0 5px 0;
    font-weight: bold;
    font-size: 20px;
}

.delete-message {
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    margin: 0;
    padding: 0 30px;
}

.a-buttons{
    display: flex;
    justify-content: space-around;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    padding-bottom:10px ;
}

.dismiss-button {
    width: 40%;
    background: #1d45b4;
    border: none;
    border-radius: 5px;
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: background .3s ease-in-out;
}

.dismiss-button:hover {
    background: #4c6fcf;
}

.delete-button {
    width: 40%;
    background-color: #EC3434;
    border: none;
    border-radius: 5px;
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: background .3s ease-in-out;
}

.delete-button:hover {
    background: #F65656;
}


@keyframes show-modal {
    0% {
        transform: scale(0);
    }

    60% {
        transform: scale(1.1);
    }

    80% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes hide-modal {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0);
    }
}