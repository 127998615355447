@import url('../../globalStyles/globalStyles.css');

.messages_users_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px;
    margin: 10px 0px;
}

.messages-chart-section {
    box-shadow: var(--box-shadow);
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #fff;
    height: 450px;
}

.messages-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zero-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    font-size: 16px;
}

.active-user-table {
    box-shadow: 0 0 1.5rem 0 rgb(136 152 170 / 30%);
    background-color: #ffff;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: scroll;
    margin-top: 0px !important;
    height: 450px;
}

.a-title,
.chart-title {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid rgba(198, 198, 224, 0.514);
    padding: 5px 0px;
}

table {
    margin-top: 20px;
}

table tr th {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    font-weight: bold !important;
    font-size: 16px;
}

table tr td {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 14px;
}

table tr:hover {
    background-color: #f1f1f1 !important;
    cursor: pointer;
}


#active-user {
    background-color: #28c76f !important;
    color: #ffff;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 12px;
}