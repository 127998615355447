@import url('../globalStyles/globalStyles.css');

.main-profile-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
    justify-content: space-between;
    margin-top: 20px;
    border-radius: 5px;
}

.user-info-section{
    border-radius: 5px;
    padding: 20px 10%;
    box-shadow: var(--box-shadow);
    background-color: #ffff;
}

.profile-title{
    font-weight: bold;
    padding: 4px 0px;
    font-size: 20px;
    color: #2f3033;
}

#user-profile-info {
    font-weight: 400;
    padding: 4px 0px;
    font-size: 14px;
    color: #5a6370;
}

#user-profile-info #info-header{
    font-weight: 600;
    padding: 4px 0px;
    font-size: 14px !important;
    color: black;
}


#error {
    font-size: 12px;
    color: rgb(221, 34, 34);
    margin-top: 5px;
}

.profile-edit-btn{
    color: #22c55e;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.profile-edit-btn:hover{
    border-bottom: #22c55e 1px solid;
}


#profile-btn-icon{
    margin-right: 3px;
    font-size: 20px;
}

.info-fields-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 15px;
}